@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: "Poppins", sans-serif;
	background: #fff;
	margin: 0 auto;
	height: 100vh;
}
/* App component */
.grid-compo {
	display: grid;
	grid-template-columns: 2fr 1fr;
	place-items: center;
}

@media (max-width: 768px) {
	.grid-compo {
		grid-template-columns: 1fr;
	}
}

/* TweetInput component */

.tweet_input {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

/* TweetImage component */

.tweet_image {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.main_post {
	width: 400px;
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
}

.tweet_post {
	padding: 16px;
	max-width: 370px;
	background-color: white;
}

.profile {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.profile img {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	margin-right: 8px;
}

.name {
	font-size: 12px;
	line-height: 16px;
	font-weight: bold;
	padding: auto;
}

.name span > .verified {
	width: 16px;
	height: 16px;
	margin-left: 3px;
}
.username {
	font-size: 12px;
	line-height: 16px;
}

.tweet_text {
	font-size: 16px;
	line-height: 23px;
	margin-top: 8px;
	white-space: pre-wrap;
}

/* ImageConfig componenr */

.config_bar {
	max-width: 360px;
}

summary {
	outline: none;
}

.tablist {
	list-style: none;
}

.tab {
	font-size: 15px;
	cursor: pointer;
	outline: none;
}

.tab:hover {
	color: #0066ff;
}

.search_section {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-flow: dense;
	gap: 5px;
	max-height: 270px;
	overflow: scroll;
	padding: 10px;
}

.search_img {
	max-width: 130px;
	border-radius: 5px;
	cursor: pointer;
}

@media (max-width: 500px) {
	.main_post {
		width: 360px;
		height: 360px;
	}
	.grid-compo {
		padding: 0;
	}
	.tweet-link-input {
		padding: 0 10px;
	}
	.tweet_post {
		max-width: 270px;
	}
}
